import React from 'react';
import neighborhoodCoin from "../assets/images/NeigbhorCoinLogo.png";
import italVegan from "../assets/images/ital_vegan_logo.png";
import unierosScreen from "../assets/images/UnierosScreenshot.png";
import './Projects.css'; // Ensure you have some custom styles or a CSS framework

const projects = [
  {
    id: 1,
    title: 'Unieros.com',
    type: 'Web Development',
    description: 'A headless WordPress site with React front-end, designed for displaying events and blogs.',
    image: unierosScreen,
    url: 'https://www.unieros.com',
  },
  {
    id: 2,
    title: 'NeighborhoodCoins',
    type: 'Web App Development',
    description: 'A digital rewards platform for event attendees, built with Google Maps API, Firebase, and Stripe integration.',
    image: neighborhoodCoin,
    url: 'https://www.neighborhoodcoin.com',
  },
  {
    id: 3,
    title: 'Ital Vegan Foods',
    type: 'Web Development',
    description: 'Vegan food platform (Coming soon).',
    image: italVegan,
    url: '#',
  },
];

const Projects = () => {
  return (
    <div className="container my-5">
      <h2 className="mb-4 text-center">Portfolio</h2>
      <p className="text-center">
        Below are some of the projects I’ve developed, showcasing my skills in web development, app integration, and design. 
        These projects span various industries, from social platforms to digital rewards.
      </p>
      <div className="row">
        {projects.map((project) => (
          <div className="col-md-6 col-lg-4 mb-4" key={project.id}>
            <div className="card project-card shadow-sm">
              <img
                src={project.image}
                className="card-img-top project-img"
                alt={project.title}
              />
              <div className="card-body">
                <h5 className="card-title">{project.title}</h5>
                <p className="card-text">{project.description}</p>
                {project.description === 'Coming soon' ? (
                  <button className="btn btn-secondary" disabled>Coming Soon</button>
                ) : (
                  <a href={project.url} target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                    View Project
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;