import React from 'react';
import backgroundImage from "../assets/images/chitech_abstract.png";
import unierosScreen from "../assets/images/UnierosScreenshot.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faBootstrap, faWordpress } from '@fortawesome/free-brands-svg-icons';

import Contact from './Contact';
import { Link } from 'react-router-dom';

const Home = () => {
  const jumbotronStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#fff",
    padding: "0 20px",
  };

  return (
    <div className="container">
      {/* Hero Section */}
      <div className="jumbotron jumbotron-fluid" style={jumbotronStyle}>
        <div className="container">
          <h1 className="display-4">Christine Brown Clayton <br/> Web Developer</h1>
          <p className="lead">Building creative and impactful web solutions.</p>
          <a href="#portfolio" className="btn btn-primary">Explore My Work</a>
        </div>
      </div>

      {/* About Section */}
      <section className="about container my-5">
        <div className="row align-items-center">
          <div className="col-md-6 mb-4 mb-md-0">
            <img className="img-fluid headshot" src={require("../assets/images/headshot.jpeg")} alt="Christine headshot" />
          </div>
          <div className="col-md-6">
            <h2>About Me</h2>
            <p className="lead">
              Hello! I'm Christine, a passionate web developer with a focus on building platforms that bring people together.
            </p>
            <p>
              My journey began with a curiosity for technology, leading me to create my first website using Drupal CMS. Since then, I've dedicated myself to developing user-friendly web solutions that bridge cultures and enhance connections.
            </p>
            <Link to="/about" className="btn btn-primary">Learn More</Link>
          </div>
        </div>
      </section>

      {/* Portfolio Section */}
      <section className="portfolio my-5" id="portfolio">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h2>Featured Project</h2>
            <h6>Unieros.com</h6>
            <p>
              Unieros is a social platform I founded to connect people from diverse cultures through in-person events. It's more than just a website – it's a community. 
            </p>
            <p>
              As the lead developer, I created a platform that not only facilitates connections but also integrates generative AI for dynamic features such as image generation and language translation.
            </p>
            <p className="lead">Tech Stack:</p>
            <p>
              <FontAwesomeIcon icon={faBootstrap} title="Bootstrap" size="2x" />{' '}
              <FontAwesomeIcon icon={faReact} title="React" size="2x" />{' '}
              <FontAwesomeIcon icon={faWordpress} title="WordPress" size="2x" />
            </p>
            <Link to="/projects" className="btn btn-primary">View More Projects</Link>
          </div>
          <div className="col-md-6">
            <img className="img-fluid" src={unierosScreen} alt="Unieros project screenshot" />
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="contact my-5">
        <Contact />
      </section>
    </div>
  );
}

export default Home;