import React from 'react';
import hello from '../assets/images/about-hello.png';
import headshot from '../assets/images/headshot.jpeg';

const About = () => {
    return (
        <div className="container about-page">
            <div className="row">
                <img className="img-fluid about-header" src={hello} alt="About Christine Brown Clayton" />
            </div>

            <div className="row mt-5">
                <div className="col-md-4 text-center mb-4">
                    <img className="img-fluid rounded-circle headshot" src={headshot} alt="Christine headshot" />
                </div>

                <div className="col-md-8">
                    <h2>About My Journey in Tech</h2>
                    <p>
                        Hi, I’m Christine! My tech journey began not in a traditional classroom, but through curiosity and a desire to bridge cultural gaps using technology.
                    </p>
                    <p>
                        It all started with creating my first website using Drupal CMS, which introduced me to the fascinating world of web development. From there, I’ve built numerous websites, helping entrepreneurs bring their visions to life.
                    </p>
                    <p>
                        My path has led me through the startup ecosystem, where I co-founded a company, to the structured world of corporate enterprise applications. These experiences have equipped me with not only technical skills but also leadership and collaboration expertise.
                    </p>
                    <p>
                        Today, I'm driven by a passion for innovation and growth, and I’m always looking for opportunities to learn and create.
                    </p>
                    <p>
                        Explore more of my journey below:
                    </p>
                    {/* Bootstrap Styled Buttons */}
                    <div className="mt-4">
                        <a href="/resume" className="btn btn-outline-primary mr-2 mb-2">View My Resume</a>
                        <a href="/projects" className="btn btn-outline-primary mr-2 mb-2">See My Portfolio</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;