import React from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';  
import Home from './components/Home';
import Resume from './components/Resume';
// import Contact from './components/Contact';
import Projects from './components/Projects';
// import Extracurricular from './components/Extracurricular';
import Skills from './components/Skills';
import About from './components/About';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Contact from './components/Contact';

import './App.css';

function App() {
  const siteProps = {
    name: "Christine Brown Clayton",
    title: "Web Developer & Content Creator",
    email: "chicagotechpro@gmail.com",
    gitHub: "chitech",
    instagram: "chitechpro",
    linkedIn: "christinebrownclayton",
    medium: "",
    twitter: "chitechpro",
    youTube: "",
  };

  return (
    <BrowserRouter>
      <div className="container">
        <Header />
      </div>
      <Routes>
        <Route path="/" element={<Home name={siteProps.name} title={siteProps.title} />} />
        <Route path="/about" element={<About />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/extracurricular" element={<Extracurricular />} /> */}
        {/* <Route path="/contact" exact element={<Contact />} /> */}
        <Route path="/skills" exact element={<Skills />} />
      </Routes>
      <div className="container">
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
