import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboard, faDisplay, faTools, faCog } from '@fortawesome/free-solid-svg-icons';
import Laptop from "../assets/images/laptop.png";

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const webhookURL = 'https://hook.us1.make.com/x6ggvwhapp7qqbwv7qumlldvsn1hxdm5'; 
    try {
      const response = await fetch(webhookURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormSubmitted(true);
        alert('Your message has been sent!');
      } else {
        alert('There was a problem submitting the form. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form. Please try again.');
    }
  };
  return (
    <div className="container my-5">
      {/* Consulting Section */}
      <div className="row align-items-center mb-5">
        <div className="col-md-6 mb-3">
          <h2>Consulting Services</h2>
          <p>
            With years of experience in web and product development, I stay up-to-date with the latest tech trends to offer customized consulting services that help elevate your digital projects.
          </p>
        </div>
        <div className="col-md-6 mb-3 text-center">
          <img src={Laptop} alt="Laptop home office" className="img-fluid" />
        </div>
      </div>

      {/* Service Offerings */}
      <div className="row text-center">
        <div className="col-md-6 mb-4">
          <FontAwesomeIcon icon={faChalkboard} size="3x" className="mb-3" />
          <h4>Web Development Strategy and Support</h4>
          <ul className="list-unstyled">
            <li>Review your current website and provide improvement recommendations.</li>
            <li>Guidance on choosing the right technology stack for your project.</li>
            <li>Strategies for improving your website’s performance and user experience.</li>
            
          </ul>
          <a href="https://buy.stripe.com/9AQeY69QC3SIaVqbIR" className="btn btn-primary">Pay for Web Support</a>
          <p>Or Hourly Website Maintenance, Content Updates, Security Technical Troubleshooting: $75/hour</p>
          <a href="https://buy.stripe.com/3cs17g5Amcpe5B6fZ6" className="btn btn-primary">Pay for Basic Website Maintenance</a>
        </div>
        
     
      </div>
      {/* Contact Form */}
      <div className="row mt-5">
        <div className="col-md-8 offset-md-2">
          <h3>Contact Me</h3>
          <p>Have questions or need more information? Use the form below to send me a message, or connect with me via social media using the links in the footer.</p>
          
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">Message</label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
          </form>

          {formSubmitted && <p className="mt-3 text-success">Thank you for your message! I will get back to you soon.</p>}
        </div>
      </div>

   
      
    </div>
    
  );
};

export default Contact;