import React from "react";

export default function Resume() {
    return (
        <div className="container my-5">
          <div className="row">
            <div className="col-md-12 mt-3">
              <h1 className="display-4">Resume</h1>
              <h2 className="resume-role">Christine Brown Clayton</h2>
            </div>
          </div>

          {/* Objective Section */}
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header resume-title">Objective</div>
                <div className="card-body">
                  <p>
                    To build innovative digital products and solutions for enterprises, entrepreneurs, and small businesses where I can utilize my skills and knowledge for both the growth of the organization and my personal development.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Experience Section */}
          <div className="row">
            <div className="col-md-6">
              <div className="card mb-4">
                <div className="card-header resume-title">Experience</div>
                <div className="card-body">
                  <h5 className="resume-role">Technical Consultant</h5>
                  <p>Self Employed, June 2023 - Present</p>
                  <ul>
                    <li>Developing custom web applications using ReactJS and NodeJS.</li>
                    <li>Implementing cloud based solutions using Azure and Google Cloud.</li>
                    <li>Providing technical consulting services to startups and small businesses.</li>
                    <li>Developing and maintaining websites using WordPress, React, Generative AI.</li>
                    
                  </ul>
                  <h5 className="resume-role">Senior Technical Consultant</h5>
                  <p>Wipro Ltd, April 2021 - May 2023</p>
                  <ul>
                    <li>Developed and optimized data solutions for diverse enterprise applications, enhancing operational efficiency and user experience.</li>
                    <li>Led a team in the delivery of static site generation using Stackbit and Sanity.</li>
                    <li>Attained AWS Cloud Foundation certification and Google Cloud training, focusing on cloud infrastructure and digital transformation.</li>
                  </ul>

                  <h5 className="resume-role">Software Developer</h5>
                  <p>HERE Technologies, Jan 2018 - March 2021</p>
                  <ul>
                    <li>Developed and maintained enterprise applications using Drupal CMS.</li>
                    <li>Collaborated with cross-functional teams to design, develop, and implement new features.</li>
                    <li>Technical lead for SaaS enterprise intranet application support and maintenance.</li>
                  </ul>

                  <h5 className="resume-role">Founder</h5>
                  <p>Unieros.com, October 2005 - Present</p>
                  <ul>
                    <li>Developed website using Drupal, WordPress, and JavaScript.</li>
                    <li>Event planning, organization, and communications.</li>
                    <li>Content creation including video, podcasting, and blogging.</li>
                  </ul>

                  <h5 className="resume-role">Technical Co-founder</h5>
                  <p>BreakingVoices, October 2014 - June 2017</p>
                  <ul>
                    <li>Developed Progressive Web App for hyperlocal media startup.</li>
                    <li>Managed a team of journalists and interns.</li>
                    <li>Developed multimedia content to increase app subscriptions.</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Skills and Certifications Section */}
            <div className="col-md-6">
              <div className="card mb-4">
                <div className="card-header resume-title">Skills</div>
                <div className="card-body">
                  <h5 className="resume-role">Computer Programming</h5>
                  <ul>
                    <li>Proficient in ReactJS, NodeJS, and SQL.</li>
                    <li>Experience with Git, Agile methodologies, and AWS.</li>
                    <li>Strong problem-solving and communication skills.</li>
                  </ul>
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-header resume-title">Certifications</div>
                <div className="card-body">
                  <h6>
                    <a href="https://www.credly.com/badges/d5f18162-3d10-4d6c-bbbe-d0891b5949b6/public_url" target="_blank" rel="noopener noreferrer">AWS Cloud Foundation</a>
                  </h6>
                  <ul>
                    <li>Cloud Computing Concepts</li>
                    <li>Amazon Web Services</li>
                  </ul>
                  
                  <h6>
                    <a href="https://coursera.org/share/de2c721caab3b30e828baf596ce6b9ea" target="_blank" rel="noopener noreferrer">Google IT Automation with Python</a>
                  </h6>
                  <ul>
                    <li>Python Programming</li>
                    <li>Automation with Python</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Education Section */}
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header resume-title">Education</div>
                <div className="card-body">
                  <h5 className="resume-role">Bachelor of Communications</h5>
                  <p>Northwestern University, 1999 - 2003</p>
                  <ul>
                    <li>Relevant coursework: Communication Systems, Web Development, Cybersecurity</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        
        </div>
    );
}