import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHtml5,faJs, faCss3Alt, faPython, faPhp, faBootstrap} from '@fortawesome/free-brands-svg-icons';

const Skills = () => {
  const descriptions = [
    'Proficient in JavaScript and its frameworks such as React and Node.js',
    'Familiar with Python and certifed in Google IT Automation with Python',
    'Experience with PHP implementation in Drupal CMS and WordPress',
    'Experience with HTML for web development',
    'Experience with CSS for web development',
    'Experience with Bootstrap for responsive web development'
  ];
    
        return (
          <div className="container">
           <div className="row">
            <h2 className="display-6 text-center white">Skills Distribution</h2>
      </div>
            <div className="row skill-icons">
        <div className="col-md-4 col-sm-6">
          <div className="skill-icon">
            <FontAwesomeIcon icon={faJs} title="JavaScript" size="2x" />
            <p>{descriptions[0]}</p>
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          <div className="skill-icon">
            <FontAwesomeIcon icon={faPython} title="Python" size="2x" />
            <p>{descriptions[1]}</p>
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          <div className="skill-icon">
            <FontAwesomeIcon icon={faPhp} title="PHP" size="2x" />
            <p>{descriptions[2]}</p>
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          <div className="skill-icon">
            <FontAwesomeIcon icon={faHtml5} title="HTML" size="2x" />
            <p>{descriptions[3]}</p>
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          <div className="skill-icon">
            <FontAwesomeIcon icon={faCss3Alt} title="CSS" size="2x" />
            <p>{descriptions[4]}</p>
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          <div className="skill-icon">
            <FontAwesomeIcon icon={faBootstrap} title="Bootsrap" size="2x" />
            <p>{descriptions[4]}</p>
          </div>
        </div>
      </div>
    </div>
  
        );
      }
   
export default Skills;
