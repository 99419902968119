import React from "react";
import logo from "../assets/images/cbc-logo.png";

export default function Header() {
    return (
        <nav className="navbar navbar-expand-lg bg-secondary-color">
            <div className="container-fluid">
                <a className="navbar-brand d-flex align-items-center" href="/" alt="navigation bar">
                    <img src={logo} width="80" height="80" className="d-inline-block align-top" alt="Logo" />
                   
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item active">
                            <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/about">About</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/projects">Projects</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/resume">Resume</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/contact">Contact</a>
                            </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}